import { ErrorType, SettingsVideoFlow, VideoAtFlow } from '../../../types';
import { api } from '../api';

export const videoFlowApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getFeed: builder.mutation<VideoAtFlow | ErrorType, SettingsVideoFlow>({
            query: (param) => ({
                url: 'utils/feed',
                method: 'POST',
                body: param,
            }),
        }),
    }),
});

export const { useGetFeedMutation } = videoFlowApi;

export const {
    endpoints: { getFeed },
} = videoFlowApi;
