import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../../app/store';
import { createSlice } from '@reduxjs/toolkit';
import { VideoStatistic } from 'src/types';

interface filteredState {
    filteredData: VideoStatistic[];
    videosForFiltered: VideoStatistic[];
}

const initialState: filteredState = {
    filteredData: [],
    videosForFiltered: [],
};

export const filteredByDateSlice = createSlice({
    name: 'videoflow',
    initialState,
    reducers: {
        setFilteredData: (state, action: PayloadAction<VideoStatistic[]>) => {
            state.filteredData = action.payload;
        },
        setVideos: (state, action: PayloadAction<VideoStatistic[]>) => {
            state.videosForFiltered = action.payload;
        },
    },
});

export const { setFilteredData, setVideos } = filteredByDateSlice.actions;

export const selectFiltered = (state: RootState) => state.filtered;

export default filteredByDateSlice.reducer;
