import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../../app/store';
import type { SegmentedValue } from 'antd/es/segmented';
import { createSlice } from '@reduxjs/toolkit';

interface VideoFlowState {
    hashOrPlaylists: boolean;
    searchValue: string;
    fetchFunc: () => void;
}

const initialState: VideoFlowState = {
    hashOrPlaylists: false,
    searchValue: '',
    fetchFunc: function () {},
};

export const videoflowSlice = createSlice({
    name: 'videoflow',
    initialState,
    reducers: {
        // переключение способа фильтрации - по тегам или по плейлистам
        turnOnHashOrPlaylists: (
            state,
            action: PayloadAction<SegmentedValue>,
        ) => {
            state.hashOrPlaylists = action.payload === 0 ? false : true;
        },
        // хранение значения поисковой строки
        getSearchValue: (state, action: PayloadAction<string>) => {
            state.searchValue = action.payload;
        },
        // функция используемая для ререндера компонента
        getFunctionForRerender: (state, action: PayloadAction<() => void>) => {
            state.fetchFunc = action.payload;
        },
    },
});

export const { turnOnHashOrPlaylists, getSearchValue, getFunctionForRerender } =
    videoflowSlice.actions;

export const selectVideoflow = (state: RootState) => state.videoflow;

export default videoflowSlice.reducer;
