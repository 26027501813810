import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../../app/store';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    refetchAfterDeleteGroup: false,
    refetchAfterCreateGroup: false,
    refetchAfterMoveFromDb: false,
    refetchAfterMoveGroup: false,
    refetchAfterShareGroup: false,
};

export const refetchingSlice = createSlice({
    name: 'labeled',
    initialState,
    reducers: {
        setRefetchAfterDeleteGroup: (state, action: PayloadAction<boolean>) => {
            state.refetchAfterDeleteGroup = action.payload;
        },
        setRefetchAfterCreateGroup: (state, action: PayloadAction<boolean>) => {
            state.refetchAfterCreateGroup = action.payload;
        },
        setRefetchAfterMoveFromDb: (state, action: PayloadAction<boolean>) => {
            state.refetchAfterMoveFromDb = action.payload;
        },
        setRefetchAfterMoveGroup: (state, action: PayloadAction<boolean>) => {
            state.refetchAfterMoveFromDb = action.payload;
        },
        setRefetchAfterShareGroup: (state, action: PayloadAction<boolean>) => {
            state.refetchAfterMoveFromDb = action.payload;
        },
    },
});

export const {
    // groups
    setRefetchAfterDeleteGroup,
    setRefetchAfterCreateGroup,
    setRefetchAfterMoveGroup,
    setRefetchAfterShareGroup,
    // db
    setRefetchAfterMoveFromDb
} = refetchingSlice.actions;

export const selectRefetching = (state: RootState) => state.refetching;

export default refetchingSlice.reducer;