import type { RootState } from '../../app/store';
import { createSlice } from '@reduxjs/toolkit';

interface sortAndFilterState {
    sort: string | null;
    country: string | null;
    dataRange: string | null;
    content: string | null;
    category: string | null;
    sub: string | null;
    vph: string | null;
    view: string | null;
}

const initialState: sortAndFilterState = {
    sort: localStorage.getItem('sort'),
    country: localStorage.getItem('country'),
    dataRange: localStorage.getItem('data-range'),
    content: localStorage.getItem('content'),
    category: localStorage.getItem('category'),
    sub: localStorage.getItem('sub'),
    vph: localStorage.getItem('vph'),
    view: localStorage.getItem('view'),
};

export const sortAndFilterSlice = createSlice({
    name: 'sort-and-filter',
    initialState,
    reducers: {
        // sort
        // filters
    },
});

export const {} = sortAndFilterSlice.actions;

export const selectSortAndFilterVideoFlow = (state: RootState) =>
    state.sortAndFilterVideoFlow;

export default sortAndFilterSlice.reducer;
