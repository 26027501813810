import { Spin as AntdSpin } from 'antd';
import mod from './style.module.scss';

type SpinProps = {
    height?: string;
    width?: string;
};

export const Spin: React.FC<SpinProps> = ({
    height = '100%',
    width = '100%',
}) => {
    return (
        <div style={{ height: height, width: width }} className={mod.spin}>
            <AntdSpin size="large" />
        </div>
    );
};
