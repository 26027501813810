import type {
    Chart24Type,
    ChartLive,
    ChartPeriodType,
    HoldType,
    Video,
} from '../../../types';
import dayjs from 'dayjs';
import { api } from '../api';

export const videosApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getVideoByChannelId: builder.query<Video[], string>({
            query: (id) => {
                return {
                    url: `video/channel?id=${id}`,
                    method: 'GET',
                };
            },
        }),
        getVideoLive: builder.query<ChartLive, string | undefined>({
            query: (id) => ({
                url: `video/live?id=${id}`,
                method: 'GET',
            }),
        }),
        getVideo24h: builder.query<Chart24Type, string | undefined>({
            query: (id) => ({
                url: `video/diagramm/24h?id=${id}`,
                method: 'GET',
            }),
        }),
        getVideoHold: builder.query<HoldType[], string | undefined>({
            query: (id) => ({
                url: `video/hold?id=${id}`,
                method: 'GET',
            }),
        }),
        // range
        getRangedLeftCharts: builder.query<{}, ChartPeriodType>({
            query: ({ id, min, value }) => ({
                url: `video/diargamm/ranged?value=${value ? value : 'COMMENTS'}&max=${dayjs(
                    new Date(),
                ).format('YYYY-MM-DD')}&min=${min}&id=${id}`,
                method: 'GET',
            }),
        }),
        getRangedRightCharts: builder.query<{}, ChartPeriodType>({
            query: ({ id, min, value }) => ({
                url: `video/diargamm/ranged?value=${value ? value : 'RATINGS_LIKES'}&max=${dayjs(
                    new Date(),
                ).format('YYYY-MM-DD')}&min=${min}&id=${id}`,
                method: 'GET',
            }),
        }),
    }),
});

export const {
    useGetVideoByChannelIdQuery,
    useGetVideoLiveQuery,
    useGetVideo24hQuery,
    useGetVideoHoldQuery,
    // range
    useLazyGetRangedLeftChartsQuery,
    useLazyGetRangedRightChartsQuery,
    usePrefetch: useVideosPrefetch,
} = videosApi;

export const {
    endpoints: {},
} = videosApi;
