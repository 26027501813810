import {
    CompetitorsReportList,
    CompetitorsSingleReport,
    YoutubeSearchTypes,
} from 'src/types';
import { api } from '../api';
import { Competitors } from 'src/types/CompetitorsTypes';

export const competitorsApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getCompetitorsList: builder.query<Competitors[], void>({
            query: () => ({
                url: 'competitors/list',
                method: 'GET',
            }),
        }),
        getCompetitorsReportList: builder.query<CompetitorsReportList, void>({
            query: () => ({
                url: 'competitors/report/list',
                method: 'GET',
            }),
        }),
        getCompetitorsReportId: builder.query<CompetitorsSingleReport, string>({
            query: (id) => ({
                url: `competitors/report/${id}`,
                method: 'GET',
            }),
        }),

        getYoutubeChannels: builder.query<YoutubeSearchTypes[], string>({
            query: (channelName) => ({
                url: `youtube/search?query=${channelName}&slow=true`,
                method: 'GET',
            }),
        }),
        addCompetitorsList: builder.mutation<
            { good: string[]; bad: string[] },
            string[]
        >({
            query: (arrayIds) => ({
                url: 'competitors/list',
                method: 'PUT',
                body: JSON.stringify(arrayIds),
                headers: {
                    'Content-Type': 'application/json',
                },
            }),
        }),
    }),
});

export const {
    useGetCompetitorsListQuery,
    useGetCompetitorsReportListQuery,
    useLazyGetCompetitorsReportIdQuery,

    useLazyGetYoutubeChannelsQuery,
    useAddCompetitorsListMutation,
} = competitorsApi;

export const {
    endpoints: { getCompetitorsList },
} = competitorsApi;
