import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../../app/store';
import { createSlice } from '@reduxjs/toolkit';

interface CustomGroupsList {
    key: number;
    channelsId: string[];
    allowed_users: string[];
    name: string;
    subgroups: {key: number, name: string; channelsId: string[]}[];
}

interface InitialState {
    customGroupsList: CustomGroupsList[]
}

const initialState: InitialState = {
    customGroupsList: []
};

export const groupListSlice = createSlice({
    name: 'labeled',
    initialState,
    reducers: {
        setCustomGroupsList: (state, action: PayloadAction<CustomGroupsList[]>) => {
            state.customGroupsList = action.payload;
        },
    },
});

export const {
    setCustomGroupsList,
} = groupListSlice.actions;

export const selectGroupList = (state: RootState) => state.groupList;

export default groupListSlice.reducer;