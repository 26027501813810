import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../../app/store';
import { createSlice } from '@reduxjs/toolkit';

export type Page = {
    label: string;
    worked: boolean;
};

export type PageManager = {
    [key: string]: Page;
};

type InitialState = {
    pageManager: PageManager;
};

export const initialState: InitialState = {
    pageManager: {
        homePage: {
            label: 'Главная',
            worked: true,
        },
        channels: {
            label: 'Каналы',
            worked: true,
        },
        account: {
            label: 'Аккаунт',
            worked: true,
        },
        realTime: {
            label: 'Real Time',
            worked: true,
        },
        settings: {
            label: 'Настройки',
            worked: true,
        },
        competitors: {
            label: 'Конкуренты',
            worked: true,
        },
        competitorsEx: {
            label: 'Конкуренты (бэта)',
            worked: true,
        },
        flow: {
            label: 'Поток',
            worked: true,
        },
    },
};

export const manegmentPageSlice = createSlice({
    name: 'manegmentPage',
    initialState,
    reducers: {
        changeWorked: (
            state,
            action: PayloadAction<{ key: string; worked: boolean }>,
        ) => {
            const { key, worked } = action.payload;
            if (state.pageManager.hasOwnProperty(key)) {
                state.pageManager[key].worked = worked;
            } else {
                console.error(
                    `Page with key '${key}' does not exist in pageManager.`,
                );
            }
        },
    },
});

export const { changeWorked } = manegmentPageSlice.actions;

export const selectManegmentPage = (state: RootState) => state.manegmentPage;

export default manegmentPageSlice.reducer;
