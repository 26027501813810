import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'src/app/store';

const initialState = {
    user: {
        username: '',
        role: '',
        uid: 0,
        blocked_in: '',
        tg: '',
        loading: true
    },
};

export const userSlice = createSlice({
    name: 'labeled',
    initialState,
    reducers: {
        getUser: (state, action: PayloadAction<{
            username: string;
            role: string;
            uid: number;
            blocked_in: string;
            tg: string;
            loading: boolean;
        }>) => {
            state.user = action.payload;
        },
    },
});

export const {
    getUser,
} = userSlice.actions;

export const selectUser = (state: RootState) => state.user;

export default userSlice.reducer;