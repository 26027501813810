// --- types
import { InputParamForAuth } from 'src/types';
// --- react - redux
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    useGetUserQuery,
    useLazyGetAuthQuery,
} from 'src/app/services/queries/user';
import { useAppDispatch } from 'src/hooks';
import { getUser as dispatchUser } from '../../../features/user';
// --- antd
import { ConfigProvider, Typography, message, theme as themeMod } from 'antd';
// --- widgets - components
import { LoginForm } from './local-components';
import { Spin } from 'src/widgets';
// --- other
import { Paths } from 'src/paths';
import cn from 'classnames';
// --- base
import { darkTheme } from 'src/baseConst';
// --- scss
import mod from './style.module.scss';

const { Text, Title } = Typography;

export const Login = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [getUser, { error }] = useLazyGetAuthQuery();
    const { data: user, isLoading } = useGetUserQuery();

    const [passwordVisible, setPasswordVisible] = useState(false);

    const onFinish = async (values: InputParamForAuth) => {
        await getUser(values).unwrap();
        navigate(Paths.home.path);
    };

    useEffect(() => {
        const isStatus = error && 'status' in error;
        const isError = isStatus ? error.status : 'Ошибка при авторизации';
        const errorMessage =
            isError === 401 ? 'Неправильный логин или пароль' : isError;
        error && message.error(errorMessage);
    }, [error]);

    useEffect(() => {
        if (user) {
            const formatUser = {
                username: user?.username,
                role: user?.role === 2 ? 'admin' : 'user',
                uid: user?.uid,
                blocked_in: user?.blocked_in,
                tg: user?.tg,
                loading: isLoading
            };
            dispatch(dispatchUser(formatUser));
        }
    }, [isLoading]);

    return isLoading ? (
        <Spin height='100vh'/>
    ) : (
        <ConfigProvider
            theme={{
                algorithm: darkTheme
                    ? themeMod.darkAlgorithm
                    : themeMod.compactAlgorithm,
            }}
            autoInsertSpaceInButton={false}
        >
            <div
                className={cn(mod.window, {
                    [mod.window_dark]: darkTheme,
                })}
            >
                <div
                    className={cn(mod.window__form, mod.form, {
                        [mod.form_dark]: darkTheme,
                    })}
                >
                    <Title className={mod.form__title} level={4}>
                        Вход в приложение
                    </Title>
                    <LoginForm
                        onFinish={onFinish}
                        passwordVisible={passwordVisible}
                        setPasswordVisible={setPasswordVisible}
                    />
                </div>
            </div>
            <div className={mod.footer}>
                <Text>© Все права защищены</Text>
                <Text>ООО "Звезда"</Text>
            </div>
        </ConfigProvider>
    );
};
