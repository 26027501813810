import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../../app/store';
import { createSlice } from '@reduxjs/toolkit';

interface PlaylistsState {
    channelIdsArray: string[];
    stateChacked: boolean;
    openPopup: boolean;
}

const initialState: PlaylistsState = {
    channelIdsArray: [],
    stateChacked: false,
    openPopup: false,
};

export const playlistsSlice = createSlice({
    name: 'videoflow',
    initialState,
    reducers: {
        addItemToArray: (state, action: PayloadAction<string>) => {
            state.channelIdsArray.push(action.payload);
        },
        removeItemToArray: (state, action: PayloadAction<string>) => {
            let newArray = state.channelIdsArray.filter(
                (id: string) => id !== action.payload,
            );
            state.channelIdsArray = newArray;
        },
        clearArray: (state) => {
            state.channelIdsArray = [];
        },

        openPopupPlaylist: (state, action: PayloadAction<boolean>) => {
            state.openPopup = action.payload;
        },

        changeChackeds: (state, action: PayloadAction<boolean>) => {
            state.stateChacked = action.payload;
        },
    },
});

export const {
    addItemToArray,
    removeItemToArray,
    clearArray,

    openPopupPlaylist,

    changeChackeds,
} = playlistsSlice.actions;

export const selectPlaylists = (state: RootState) => state.playlists;

export default playlistsSlice.reducer;
