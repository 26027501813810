import { BadgesType, ParamsBadges } from '../../../types';
import { api } from '../api';

const append = 'action=append';
const remove = 'action=remove';

export const badgeApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getBadge: builder.query<BadgesType[], void>({
            query: () => ({
                url: 'badge',
                method: 'GET',
            }),
        }),
        addBadge: builder.mutation<string, ParamsBadges>({
            query: ({ videoId, badgeId }) => ({
                url: `badge/?video=${videoId}&badge=${badgeId}&${append}`,
                method: 'PUT',
            }),
        }),
        removeBadge: builder.mutation<string, ParamsBadges>({
            query: ({ videoId, badgeId }) => ({
                url: `badge/?video=${videoId}&badge=${badgeId}&${remove}`,
                method: 'PUT',
            }),
        }),
    }),
});

export const { useGetBadgeQuery, useAddBadgeMutation, useRemoveBadgeMutation } =
    badgeApi;

export const {
    endpoints: {},
} = badgeApi;
