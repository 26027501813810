// --- types
import { RangeValue } from 'src/types/OtherTypes';
// --- antd
import { DatePicker, Space, Typography, Button, Checkbox } from 'antd';
import locale from 'antd/es/date-picker/locale/ru_RU';
// --- dayjs
import 'dayjs/locale/ru';
import dayjs, { Dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat.js';
// --- scss
import mod from './style.module.scss';

const { Title } = Typography;
const { RangePicker } = DatePicker;

type FilterPeriodProps = {
    checkbox?: boolean;
    functionProp?: (
        values: RangeValue<Dayjs>,
        formatString: [string, string],
    ) => void;
    button?: boolean;
};

dayjs.locale('ru');
dayjs.extend(customParseFormat);
dayjs.extend(localizedFormat);
dayjs().format('L');
const dateFormat = 'DD/MM/YYYY';

export const FilterPeriod: React.FC<FilterPeriodProps> = ({
    checkbox,
    functionProp,
    button,
}) => {
    return (
        <div className={mod.filter}>
            <Space direction="horizontal" size={16}>
                <Title style={{ margin: 0 }} level={5}>
                    Период
                </Title>
                {checkbox ? <Checkbox>За весь период</Checkbox> : ''}
            </Space>
            <Space direction="horizontal" size={8}>
                <RangePicker
                    locale={locale}
                    className={mod.filter__range}
                    onChange={(value: RangeValue<Dayjs>) => {
                        functionProp
                            ? functionProp(value, [dateFormat, dateFormat])
                            : '';
                    }}
                    format={dateFormat}
                />
                {button && (
                    <Button type="primary" size={'middle'}>
                        Применить
                    </Button>
                )}
            </Space>
        </div>
    );
};
