interface Path {
    path: string;
    index: string;
    title: string;
}

export const Paths = {
    home: {
        path: '/',
        index: '0',
        title: 'Главная',
    },
    channels: {
        path: '/channels',
        index: '1',
        title: 'Каналы',
    },
    singleChannel: {
        path: '/channels',
        index: '2',
        title: 'Канал',
    },
    singleVideo: {
        path: '/channels',
        index: '3',
        title: 'Видео',
    },
    account: {
        path: '/account',
        index: '4',
        title: 'Аккаунт',
    },
    realtime: {
        path: '/realtime',
        index: '5',
        title: 'Статистика в реальном времени',
    },
    settings: {
        path: '/settings',
        index: '6',
        title: 'Настройки',
    },
    competitors: {
        path: '/competitors',
        index: '7',
        title: 'Конкуренты',
    },
    videoflow: {
        path: '/videoflow',
        index: '8',
        title: 'Поток',
    },
    playlist: {
        path: '/playlist',
        index: '9',
        title: 'Плейлист',
    },
    login: {
        path: '/login',
        index: '10',
        title: 'Вход',
    },
    // administration
    userManagement: {
        path: '/user-management',
        index: '11',
        title: 'Управление страницами',
    },
    // sistem-pages
    workProgress: {
        path: '/work-progress',
        index: '12',
        title: 'Выполняющиеся работы',
    },
} as const;

export type PathsType = { [K in keyof typeof Paths]: Path };