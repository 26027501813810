import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../../app/store';
import { createSlice } from '@reduxjs/toolkit';

interface DropDownRadioState {
    playlistId: string;
}

const initialState: DropDownRadioState = {
    playlistId: '',
};

export const dropDownRadioSlice = createSlice({
    name: 'videoflow',
    initialState,
    reducers: {
        setPlaylistId: (state, action: PayloadAction<string>) => {
            state.playlistId = action.payload;
        },
    },
});

export const { setPlaylistId } = dropDownRadioSlice.actions;

export const selectDropDownRadio = (state: RootState) => state.dropDownRadio;

export default dropDownRadioSlice.reducer;
