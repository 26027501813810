import type { InputParamForAuth } from "src/types";
import React from "react";
import { Button, Form, Input } from "antd";
import mod from './style.module.scss'

interface LoginFormProps {
    onFinish: (arg: InputParamForAuth) => void;
    passwordVisible: boolean;
    setPasswordVisible: (arg: boolean) => void;
}

export const LoginForm: React.FC<LoginFormProps> = ({
    onFinish,
    passwordVisible,
    setPasswordVisible,
}) => {
    return (
        <Form
            name="login"
            initialValues={{ remember: true }}
            onFinish={onFinish}
        >
            <Form.Item
                name="username"
                rules={[
                    {
                        required: true,
                        message: 'Введите имя пользователя!',
                    },
                ]}
            >
                <Input placeholder="Имя пользователя" />
            </Form.Item>
            <Form.Item
                name="password"
                rules={[{ required: true, message: 'Введите пароль!' }]}
            >
                <Input.Password
                    visibilityToggle={{
                        visible: passwordVisible,
                        onVisibleChange: setPasswordVisible,
                    }}
                    placeholder="Пароль"
                />
            </Form.Item>
            <Form.Item className={mod.form__button}>
                <Button type="primary" htmlType="submit">
                    Войти
                </Button>
            </Form.Item>
        </Form>
    );
};
