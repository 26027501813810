import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../../app/store';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    labeledHoursVPH: [] as {label: number, id: string}[],
    labeledDaysVPH: [] as {label: number, id: string}[],
    labeledHoursNSPH: [] as {label: number, id: string}[],
    labeledDaysNSPH: [] as {label: number, id: string}[],
    labeledHoursSPV: [] as {label: number, id: string}[],
    labeledDaysSPV: [] as {label: number, id: string}[],
};

export const labelDataSlice = createSlice({
    name: 'labeled',
    initialState,
    reducers: {
        setLabelHoursVPH: (state, action: PayloadAction<{label: number, id: string}>) => {
            state.labeledHoursVPH.push(action.payload);
        },
        setLabelDaysVPH: (state, action: PayloadAction<{label: number, id: string}>) => {
            state.labeledDaysVPH.push(action.payload);
        },
        setLabelHoursNSPH: (state, action: PayloadAction<{label: number, id: string}>) => {
            state.labeledHoursNSPH.push(action.payload);
        },
        setLabelDaysNSPH: (state, action: PayloadAction<{label: number, id: string}>) => {
            state.labeledDaysNSPH.push(action.payload);
        },
        setLabelHoursSPV: (state, action: PayloadAction<{label: number, id: string}>) => {
            state.labeledHoursSPV.push(action.payload);
        },
        setLabelDaysSPV: (state, action: PayloadAction<{label: number, id: string}>) => {
            state.labeledDaysSPV.push(action.payload);
        },
    },
});

export const {
    setLabelHoursVPH,
    setLabelDaysVPH,
    setLabelHoursNSPH,
    setLabelDaysNSPH,
    setLabelHoursSPV,
    setLabelDaysSPV
} = labelDataSlice.actions;

export const selectLabel = (state: RootState) => state.labeled;

export default labelDataSlice.reducer;