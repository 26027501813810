import type {
    ChannelReport,
    SingleChannel,
    Chart48ChannelType,
    NewContentType,
    HoldChannel,
} from 'src/types';
import dayjs from 'dayjs';
import { api } from '../api';

export const channelsApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getAllChannels: builder.query<SingleChannel[], void>({
            query: () => ({
                url: 'channel/list',
                method: 'GET',
            }),
        }),
        getChannelReport: builder.query<ChannelReport[], string>({
            query: (id) => ({
                url: `channel/report?max=${dayjs(new Date()).format(
                    'YYYY-MM-DD',
                )}&min=2000-01-01&id=${id}`,
                method: 'GET',
            }),
        }),
        getChannelHold: builder.query<HoldChannel[], string>({
            query: (id) => ({
                url: `/channel/hold?id=${id}`,
                method: 'GET',
            }),
        }),
        getChannel48h: builder.query<Chart48ChannelType, string>({
            query: (id) => ({
                url: `channel/live?id=${id}`,
                method: 'GET',
            }),
        }),
        getChannelNewContent: builder.query<NewContentType, string>({
            query: (id) => ({
                url: `channel/newvideoblock?id=${id}`,
                method: 'GET',
            }),
        }),
    }),
});

export const {
    useGetAllChannelsQuery,
    useGetChannelNewContentQuery,
    useGetChannelHoldQuery,
    useGetChannelReportQuery,
    useGetChannel48hQuery,
    usePrefetch: useChannelsPrefetch,
} = channelsApi;

export const {
    endpoints: {
        getAllChannels,
        getChannelReport,
        getChannelHold,
        getChannel48h,
        getChannelNewContent,
    },
} = channelsApi;
