import { ThemeType } from './types';

export const base64 = 'data:image/png;base64,';

export const youtube = 'https://www.youtube.com/watch?v=';
export const youtubeChannel = 'https://www.youtube.com/channel/';

export const EMPTY_CHANNEL = 'Елена Передерко';

export const telegram = 'https://t.me/lodreports_bot';

// --- theme ---

export const theme = localStorage.getItem('theme') as ThemeType;
export const DARK = 'dark';
export const darkTheme = theme === DARK;

// --- badges ---

export const upBadge = '64f433e0e3890bbb64918bf8';
export const downBadge = '64f433b2e3890bbb64918bf7';

// --- errors ---

export const badRequests400: number[] = [400, 401, 402, 403, 404, 405, 406];
export const badResponse500: number[] = [500, 501, 502, 503, 504, 505];

// --- date format ---

export const dateFormatWithTime = 'DD.MM.YYYY HH:mm:ss';
export const dateFormatWithTimeNoSec = 'DD.MM.YYYY HH:mm';
export const dateFormat = 'DD.MM.YYYY';
export const dateFormatMMYY = 'MM.YYYY';

// --- regex ---

export const regexChannelLinksValidation = /\bhttp[s]?\:\/\/([w]{3,3}\.)?(youtube\.com)\/(user\/)?(channel\/)?([@]?[a-z, A-Z, 0-9, \-, \_]{1,}).*$/
